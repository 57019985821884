(function startProductsSlider() {
    const productCarousel = document.querySelector('.products-container__carousel')
    $(productCarousel).slick({
        arrows: false,
        dots: false,
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000
    })

    const productLabels = document.querySelectorAll('.labels .product-label')
    productLabels.forEach(label => handleLabelEvent(label))

    function handleLabelEvent(label) {
        label.addEventListener('click', handleLabelClick)
    }

    function handleLabelClick(event) {
        const label = event.target
        const slideIndex = label.dataset.slideIndex

        clearActiveLabels()
        label.classList.add('active')

        $(productCarousel).slick('slickGoTo', slideIndex)
    }

    $(productCarousel).on('beforeChange', function(_, __, ___, nextSlide) {
        handleSlideChange(nextSlide)
    })

    function handleSlideChange(slideIndex) {
        clearActiveLabels()
        productLabels.forEach(label => handleActiveLabelOnChange(label, slideIndex))
    }

    function handleActiveLabelOnChange(label, slideIndex) {
        const labelSlideIndex = label.dataset.slideIndex

        if(labelSlideIndex == slideIndex) label.classList.add('active')
    }

    function clearActiveLabels() {
        productLabels.forEach(label => label.classList.remove('active'))
    }
    
})()